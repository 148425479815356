@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollable::-webkit-scrollbar:vertical {
  width: 11px;
}

.scrollable::-webkit-scrollbar:horizontal {
  display: none;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #e8e8e8;
  background-color: rgba(0, 0, 0, 0.5);
}
